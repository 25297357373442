<template>
	<div></div>
</template>

<script>
export default {
	props: {
		service: {
			type: null,
			default: () => [],
		},
	},
	watch: {
		service: {
			handler() {
				this.resumeCurrentPageState()
			},
			deep: true,
		},
		$route(newVal) {
			this.resumeCurrentPageState()
		},
	},
	mounted() {
		this.resumeCurrentPageState()
	},
	methods: {
		resumeCurrentPageState() {
			const params = { id: this.service.id }
			const onfieldState = this.service.onfield_state
			if (onfieldState) {
				switch (onfieldState) {
					case 'bind_ticket':
						this.$router.replace({ name: 'onfield/bind_ticket', params })
						break;
					case 'pre_service':
						this.$router.replace({ name: 'onfield/pre_service', params })
						break;
					case 'cost_estimate':
						this.$router.replace({ name: 'onfield/cost_estimate', params })
						break;
					case 'service_job':
						this.$router.replace({ name: 'onfield/service_job', params })
						break;
					case 'claim':
						this.$router.replace({ name: 'onfield/claim', params })
						break;
					case 'pre_delivery':
						this.$router.replace({ name: 'onfield/pre_delivery', params })
						break;
					case 'return':
						this.$router.replace({ name: 'onfield/return', params })
						break;
					case 'cancel':
						this.$router.replace({ name: 'onfield/cancel', params })
						break;
					case 'done':
						this.$router.replace({ name: 'onfield/close', params })
						break;
				}
			}
		},
	},
}
</script>
